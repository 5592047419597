"use client"

import Link from "next/link"
import Carousel from "react-multi-carousel"

import ProductCard from "@/components/product-card"

import ArrowCustom from "./ArrowCustom"
import { type TProduct } from "../product-card/type"
import { ORDER_RATING_REVIEW } from "@/commons/constants/flags"
import ROUTE from "@/config/route"
import type { SearchProductStrategy } from "@/federatedGql/graphql"
import type { FeatureFlag } from "@/utils/getFlag"

import "react-multi-carousel/lib/styles.css"
import "./style.css"

type TCarouselProductsProps = {
  items: TProduct[]
  featureFlags?: FeatureFlag
  strategy: SearchProductStrategy
  isProductAccelerationEnabled?: boolean
  isNewTaxCalculationEnabled?: boolean
}

const CarouselProducts = ({
  items,
  featureFlags,
  strategy,
  isProductAccelerationEnabled = false,
  isNewTaxCalculationEnabled = false,
}: TCarouselProductsProps) => {
  const responsive = {
    xl: {
      breakpoint: { max: 3000, min: 1280 },
      items: 6,
    },
    lg: {
      breakpoint: { max: 1279, min: 900 },
      items: 5,
    },
    md: {
      breakpoint: { max: 899, min: 641 },
      items: 4,
    },
    sm: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
  }

  const isRatingReviewEnabled = featureFlags?.[ORDER_RATING_REVIEW]?.isEnabled

  return (
    <div className="group relative">
      <Carousel
        swipeable={false}
        draggable={false}
        autoPlay={false}
        infinite={false}
        slidesToSlide={6}
        responsive={responsive}
        customLeftArrow={<ArrowCustom arrow="left" />}
        customRightArrow={<ArrowCustom arrow="right" />}
        itemClass="unset-transform-style"
      >
        {items.map((product, idx) => {
          return (
            <Link
              className="md"
              // this href should be adjusted when mock product list replaced with actual responses
              href={ROUTE.product(
                product?.username || "testerrichap",
                product?.slug || ""
              )}
              key={idx}
            >
              <ProductCard
                product={product}
                isRatingReviewEnabled={isRatingReviewEnabled}
                strategy={strategy}
                isProductAccelerationEnabled={isProductAccelerationEnabled}
                isNewTaxCalculationEnabled={isNewTaxCalculationEnabled}
              />
            </Link>
          )
        })}
      </Carousel>
    </div>
  )
}

export default CarouselProducts
