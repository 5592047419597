import { cx } from "class-variance-authority"
import { Info } from "react-feather"
import ProductFallbackImage from "shared-assets/images/broken-product-image.webp"
import { ImageWithFallback, RatingStars, Tooltip } from "shared-ui"
import {
  decodeHTMLEntity,
  formatIntoCurrency,
  generateBlurDataUrl,
  toLowerCaseLocation,
} from "shared-utils"

import {
  regionPriceTooltipInfo,
  shortenRegionPriceTooltipInfo,
} from "./constants"
import { type TProduct } from "./type"
import { customFormatRegionPrice } from "./utils/customFormatRegionPrice"
import { productTypeLabel } from "./utils/handleProductTypeLabel"
import type { SearchProductStrategy } from "@/federatedGql/graphql"
import { ProductTkdnStatus } from "@/federatedGql/graphql"
import { sortProductLabelsByName } from "@/utils/common"
import { formatSold } from "@/utils/formatSold"
import { roundUpDigits } from "@/utils/number"

type TProductCardProps = {
  product: TProduct
  href?: string
  isRatingReviewEnabled?: boolean
  strategy?: SearchProductStrategy
  isProductAccelerationEnabled?: boolean
  isNewTaxCalculationEnabled?: boolean
}

const ProductCard = ({
  product,
  isRatingReviewEnabled = false,
  strategy,
  isProductAccelerationEnabled = false,
  isNewTaxCalculationEnabled = false,
}: TProductCardProps) => {
  const isOutOfStock = product?.stockAccumulation === 0
  const stockLabel = isOutOfStock ? "Stok Habis" : "Pre Order"
  const productLabels =
    product?.labels?.filter((label) => label !== "Pre Order") || []
  const isPriceAfterTax = (product.ppnPercentage || product.ppnBmPercentage) > 0

  const showProductRating = isRatingReviewEnabled && !!product.rating

  const renderProductLabels = () => {
    const excludedLabels = [
      "Inovasi",
      "Ramah Lingkungan",
      "PPN",
      "PPNBM",
      "Verified",
      "Konsolidasi",
      "Harga Tetap",
    ]
    return sortProductLabelsByName(productLabels, "PDN")
      .filter((label) => !excludedLabels.includes(label))
      .map((label, idx) => (
        <div
          key={`label_${idx}`}
          className="flex items-center rounded-2 bg-tertiary25 px-1 font-semibold text-tertiary500"
        >
          {label}
          {label === "Harga Zonasi" && <Info size={12} className="ml-1" />}
        </div>
      ))
  }

  const getProductPrice = () => {
    const hasMultiVariant = product.variants && product.variants.length > 1

    const defaultPrice = isPriceAfterTax
      ? product.defaultPriceWithTax
      : product.defaultPrice

    if (hasMultiVariant && product.variants) {
      const availableVariants = product.variants.filter(
        (variant) => variant.stock > 0
      )
      if (availableVariants.length > 0) {
        const lowestPriceVariant = availableVariants.reduce(
          (lowest, variant) => {
            const variantPrice = isPriceAfterTax
              ? variant.priceWithTax
              : variant.price
            return variantPrice < lowest ? variantPrice : lowest
          },
          Infinity
        )

        return formatIntoCurrency({ value: lowestPriceVariant })
      }
    }

    if (product.isRegionPrice) {
      const minPrice = isPriceAfterTax
        ? product.minPriceWithTax
        : product.minPrice
      const maxPrice = isPriceAfterTax
        ? product.maxPriceWithTax
        : product.maxPrice

      return product.isRegionPrice
        ? customFormatRegionPrice(minPrice, maxPrice)
        : formatIntoCurrency({
            value: defaultPrice,
            range: { min: minPrice, max: maxPrice },
          })
    }

    return formatIntoCurrency({ value: defaultPrice })
  }

  const productPrice = getProductPrice()

  const isProductPending =
    isProductAccelerationEnabled && product.status === "PENDING"

  const isGreyOutPicture = isOutOfStock || isProductPending

  const renderProductPrice = () => {
    const PRICE_TOOLTIP_TEXT = {
      PPnBM: "Termasuk PPN & PPnBM",
      PPN: "Termasuk PPN",
      FREE: "Bebas/Tidak Dikenakan PPN",
      // Legacy tooltips - Easy to remove this section later
      LEGACY: {
        WITH_TAX: "Termasuk pajak",
        NO_TAX: "Tidak Kena Pajak",
      },
    } as const

    const getTaxTooltipText = () => {
      // Legacy check - Remove this block when legacy support is no longer needed
      if (!isNewTaxCalculationEnabled) {
        return isPriceAfterTax
          ? PRICE_TOOLTIP_TEXT.LEGACY.WITH_TAX
          : PRICE_TOOLTIP_TEXT.LEGACY.NO_TAX
      }

      // New tax tooltip implementation
      if (product.ppnBmPercentage > 0) return PRICE_TOOLTIP_TEXT.PPnBM
      if (product.ppnPercentage > 0) return PRICE_TOOLTIP_TEXT.PPN
      return PRICE_TOOLTIP_TEXT.FREE
    }

    return (
      <div className="w-fit truncate text-sm font-bold text-tertiary500">
        {!product.isRegionPrice ? (
          <Tooltip withPortal title={getTaxTooltipText()}>
            <div>{productPrice}</div>
          </Tooltip>
        ) : (
          <span className="flex items-center gap-2">
            <Tooltip withPortal title={getTaxTooltipText()}>
              <div>{productPrice}</div>
            </Tooltip>
            <Tooltip
              title={
                product.isRegionPrice
                  ? shortenRegionPriceTooltipInfo
                  : regionPriceTooltipInfo
              }
            >
              <Info className="min-w-min text-tertiary400" size={18} />
            </Tooltip>
          </span>
        )}
      </div>
    )
  }

  return (
    <div className="flex h-full">
      <div className="group/card bg-white shadow-lg relative flex-1 overflow-hidden rounded-4 border border-tertiary50 text-xs font-normal">
        <div className="relative w-full">
          <div
            className={cx(
              "relative w-full pt-[100%]",
              isGreyOutPicture && "!bg-[#fff] brightness-50"
            )}
          >
            <ImageWithFallback
              key={`product-image-${product.slug}`}
              alt={product.name}
              fallbackSrc={ProductFallbackImage}
              fill
              loading="lazy"
              placeholder="blur"
              quality={product?.images?.[0] ? 70 : 100}
              src={product.images?.[0] ?? ProductFallbackImage}
              style={{ objectFit: "cover" }}
              blurDataURL={generateBlurDataUrl(187, 188)}
            />
          </div>

          <div className="rounded absolute left-0 top-0 flex w-full gap-2 bg-transparent p-2">
            <div className="rounded-2 bg-information25 px-2 py-1 text-xs font-semibold text-information500">
              {productTypeLabel(product.type)}
            </div>
            {(product.isPreOrder || isOutOfStock) && !isProductPending && (
              <div className="rounded-2 bg-tertiary500 p-1 text-xs font-semibold text-warning25">
                {stockLabel}
              </div>
            )}
            {isProductPending && (
              <div className="z-10 rounded-2 bg-tertiary500 p-1 text-xs font-semibold text-warning25">
                Belum Aktif
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-1 p-3">
          <div className="line-clamp-2 text-sm text-tertiary500">
            {decodeHTMLEntity(product.name)}
          </div>
          {renderProductPrice()}
          {product?.tkdn?.status === ProductTkdnStatus.Approved &&
            !product.labels?.includes("Import") && (
              <div>
                <span className="rounded-2 bg-success700 px-1 font-semibold text-tertiary25">
                  TKDN+BMP {roundUpDigits(product.tkdn.tkdnBmp)}%
                </span>
              </div>
            )}

          <div className="flex flex-wrap gap-1">{renderProductLabels()}</div>
          <div className="flex gap-1">
            {product.isSellerUMKK && (
              <div className="bg-information25 px-1 font-semibold text-information500">
                UMKK
              </div>
            )}
            <div className="h-4 cursor-pointer overflow-hidden text-tertiary300">
              <span className="block truncate capitalize transition-transform duration-300 group-hover/card:-translate-y-4">
                {toLowerCaseLocation(product.location.child.name)}
              </span>
              <span className="block truncate transition-transform duration-300 group-hover/card:-translate-y-4">
                {product.sellerName}
              </span>
            </div>
          </div>
          <div className="text-xs text-tertiary300">
            {showProductRating ? (
              <div className="mt-1 flex items-center gap-1">
                <div className="flex items-center">
                  <RatingStars
                    id={`product-card-${strategy ?? ""}-${product.id}`}
                    size="tiny"
                    starCount={1}
                    isEditable={false}
                    withDescription={false}
                    score={1}
                  />
                  <div className="text-caption-sm-regular text-tertiary300">
                    {product.rating?.average.toFixed(1)}
                  </div>
                </div>
                <div className="text-tertiary200">•</div>
                <div className="text-caption-sm-regular">
                  {formatSold(product.unitSold)}
                </div>
              </div>
            ) : (
              <div className="ml-1">{formatSold(product.unitSold)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
